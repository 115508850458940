<template>
  <el-main>
    <el-button style="margin-bottom: 10px" @click="(goodsForm.page = 1), getGoodsReward(0)" type="primary" size="small">选择商品</el-button>
    <el-form class="el-form-search" label-width="90px">
      <el-form-item label="商品信息：">
        <el-input v-model="title" size="small" placeholder="请输入商品信息"></el-input>
      </el-form-item>
      <el-form-item label=" " label-width="20px">
        <el-button type="primary" size="small" @click="search">搜索</el-button>
        <el-button type="text" size="small" @click="cancelSearch">清空搜索条件</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="list" row-key="id" :header-cell-style="{ 'background-color': '#F8F9FA' }">
      <template slot="empty">
        <No />
      </template>
      <el-table-column prop="image" label="商品信息" width="280" align="center">
        <template v-slot="{ row }">
          <div class="goods-info">
            <el-image :src="row.image"></el-image>
            <div class="title">
              <div>{{ row.title }}</div>
              <div v-if="row.sku_name">规格：{{ row.sku_name }}</div>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="price" label="价格" align="center"></el-table-column>
      <el-table-column prop="reward" label="奖励额度" align="center"></el-table-column>
      <el-table-column prop="total" label="库存" align="center"></el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button @click="edit(scope.row)" type="text" size="small">修改额度</el-button>
          <el-button @click="remove(scope.row)" type="text" size="small">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <Paging :total="total" :page="page" :pageNum="rows" @updatePageNum="updateData"></Paging>
    <el-dialog title="选择商品" :visible.sync="showGoodsReward" width="800px">
      <el-form class="goods-search" label-width="90px">
        <el-form-item label="搜索商品：">
          <el-input v-model="goodsForm.goods_name" size="small" placeholder="请输入商品名称"></el-input>
        </el-form-item>
        <el-form-item label="选择分类：">
          <el-cascader size="small" clearable v-model="goodsForm.classify_id" :options="goodsClass" @change="typeChange"></el-cascader>
        </el-form-item>
        <el-form-item label=" " label-width="20px">
          <el-button @click="getGoodsReward(0)" type="primary" size="small">搜索</el-button>
        </el-form-item>
      </el-form>
      <el-table
        :data="goodsRewardList"
        :cell-class-name="tableCellClassName"
        row-key="id"
        :tree-props="{ children: 'goods_spec', hasChildren: 'hasChildren' }"
        :header-cell-style="{ 'background-color': '#F8F9FA' }"
      >
        <template slot="empty">
          <No />
        </template>
        <el-table-column prop="image" label="商品信息" width="280" align="center">
          <template v-slot="{ row }">
            <div class="goods-info">
              <el-image :src="row.goods_picture"></el-image>
              <div class="title">
                <div style="-webkit-line-clamp: 1">
                  {{ row.goods_name }}
                </div>
                <div v-if="!row.is_spec">库存：{{ row.goods_stock }}</div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="goods_price" label="单价" align="center"></el-table-column>
        <el-table-column prop="reward" label="奖励额度" width="120" align="center">
          <template v-slot="{ row }">
            <el-input v-model="row.reward" :disabled="row.is_reward || row.is_spec" size="small"></el-input>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template v-slot="{ row }">
            <el-button v-if="type" @click="comfirmGoodsReward(row)" type="text">修改</el-button>
            <el-button v-else @click="comfirmGoodsReward(row)" :disabled="row.is_reward || row.is_spec" type="text" size="small">{{ row.is_reward ? '已选' : '选择' }}</el-button>
          </template>
        </el-table-column>
      </el-table>
      <Paging :total="total_number" :page="goodsForm.page" :pageNum="goodsForm.rows" @updatePageNum="updateData1"></Paging>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showGoodsReward = !1">取 消</el-button>
      </span>
    </el-dialog>
  </el-main>
</template>

<script>
import Paging from '@/components/paging';
import _ from "lodash";
export default {
  components: {
    Paging,
  },
  data() {
    return {
      id: '',
      page: 1,
      rows: 10,
      title: '',
      list: [],
      total: 0,
      type: 0,
      total_number: 0,
      showGoodsReward: !1,
      goodsRewardList: [],
      goodsForm: {
        page: 1,
        rows: 5,
        goods_name: '',
        classify_id: '',
      },
    };
  },
  computed: {
    goodsClass() {
      let goodsTypeList = _.cloneDeep(this.$store.getters.goodsTypeList);
      goodsTypeList.unshift({
        value: 0,
        label: '全部',
      });
      return goodsTypeList;
    },
  },
  created() {
    this.$store.dispatch('goods/getTypeList');
    this.getList();
  },
  methods: {
    search() {
      this.page = 1;
      this.getList();
    },
    cancelSearch() {
      this.page = 1;
      this.rows = 10;
      this.title = '';
      this.getList();
    },
    updateData(val, status) {
      if (status == 0) {
        this.rows = val;
        this.getList();
      } else {
        this.page = val;
        this.getList();
      }
    },
    updateData1(val, status) {
      if (status == 0) {
        this.goodsForm.rows = val;
        this.getGoodsReward();
      } else {
        this.goodsForm.page = val;
        this.getGoodsReward();
      }
    },
    tableCellClassName({ row, column, rowIndex }) {
      if (column.label == '商品信息') {
        if (row.is_spec) {
          return 'info';
        } else {
          return 'info-padding info';
        }
      }
    },
    typeChange(val) {
      val.length - 1 ? (this.goodsForm.classify_id = val[1]) : (this.goodsForm.classify_id = val[0]);
    },
    getList() {
      this.$axios
        .post(this.$api.push.shopRewardList, {
          page: this.page,
          rows: this.rows,
          title: this.title,
        })
        .then(res => {
          if (res.code == 0) {
            this.list = res.result.list;
            this.total = res.result.total;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    getGoodsReward(type) {
      let data = {};
      this.type = type ? 1 : 0;
      this.type ? (data.id = this.id) : (data = this.goodsForm);
      this.$axios.post(type ? this.$api.push.goodsRewardOne : this.$api.push.goodsReward, data).then(res => {
        if (res.code == 0) {
          if (type) {
            this.goodsRewardList = [
              {
                goods_picture: res.result.image,
                goods_price: res.result.price,
                goods_name: res.result.title,
                goods_stock: res.result.total,
                reward: res.result.reward,
                id: res.result.id,
                goods_id: res.result.goods_id,
              },
            ];
            this.total_number = 0;
          } else {
            let list = res.result.list;
            for (let i in list) {
              if (list[i].goods_spec) {
                list[i].goods_spec.map(item => {
                  item.goods_picture = item.picture;
                  item.goods_price = item.price;
                  item.goods_name = '规格：' + item.title;
                  item.reward = item.reward || '';
                  item.sku_id = item.id;
                  item.goods_id = list[i].id;
                  item.name = list[i].goods_name;
                  item.goods_stock = item.stock;
                });
              }
            }
            this.goodsRewardList = list;
            this.total_number = res.result.total_number;
          }
          this.showGoodsReward = !0;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    comfirmGoodsReward(row) {
      if (!row.reward) return this.$message.warning('请填写奖励额度');
      let data = {
        title: row.goods_name,
        image: row.goods_picture,
        goods_id: this.type ? row.goods_id : row.id,
        price: row.goods_price,
        total: row.goods_stock,
        reward: row.reward,
      };
      if (this.type) data.id = row.id;
      if (row.picture) {
        data.sku_id = row.sku_id;
        data.title = row.name;
        data.goods_id = row.goods_id;
      }
      this.$axios.post(this.type ? this.$api.push.goodsRewardEdit : this.$api.push.goodsRewardAdd, data).then(res => {
        if (res.code == 0) {
          this.showGoodsReward = !1;
          this.$message.success(this.type ? '修改成功' : '选择成功');
          this.getList();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    edit(row) {
      this.id = row.id;
      this.goodsForm.page = 1;
      this.getGoodsReward(1);
    },
    remove(row) {
      this.$confirm('确认是否删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.$axios
          .post(this.$api.push.goodsRewardDel, {
            id: row.id,
          })
          .then(res => {
            if (res.code == 0) {
              this.$message.success('删除成功');
              this.getList();
            } else {
              this.$message.error(res.msg);
            }
          });
      });
    },
  },
};
</script>

<style lang="less">
.el-main {
  background: #fff;
  .goods-info {
    display: flex;
    align-items: center;
    .el-image {
      width: 50px;
      height: 50px;
      margin-right: 15px;
      flex-shrink: 0;
    }
    .title {
      text-align: left;
      div:nth-child(1) {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
      }
      div:nth-child(2) {
        font-size: 12px;
      }
    }
  }
  .goods-search {
    display: flex;
  }
}
.info {
  .el-icon-arrow-right{
    font-weight: bold;
    font-size: 16px;
  }
  .cell {
    display: flex !important;
    align-items: center !important;
  }
}
.info-padding {
  .cell {
    padding-left: 33px !important;
  }
}
</style>